<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card :disabled="loading" :loading="loading">
      <v-card-title>
        {{ $t("hr.expense_approval.expense_approval") }}
        <v-spacer />
        <v-btn depressed icon @click="onClickCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3" v-if="expense">
        <validation-observer ref="form">
          <v-flex xs12 mt-2 mb-3>
            <span
              v-html="
                $t('hr.expense_approval.message', [
                  `${expense.expense.employee.firstName} ${expense.expense.employee.lastName}`,
                  $helpers.formattedDate(expense.expense.timestamp),
                  expense.expense.type.name,
                  $helpers.getConvertCurrency(expense.expense.requested)
                ])
              "
            />
          </v-flex>
          <v-flex xs12>
            <validation-provider
              :name="$t('currency.approved_amount')"
              rules="required|max:255|min_value:0|max_value:999999"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model.number="expenseApprovalDTO.approvedAmount"
                v-numeric.number.decimal
                :label="$t('currency.approved_amount')"
                :error-messages="errors"
                :append-icon="$helpers.getCurrencyIcon(currencyCode)"
              />
            </validation-provider>
          </v-flex>
          <v-flex xs12>
            <validation-provider :name="$t('global.note')" rules="max:512" v-slot="{ errors }">
              <v-text-field
                v-model.trim="expenseApprovalDTO.note"
                name="note"
                :label="$t('global.note')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-flex>

          <v-flex xs12>
            <v-radio-group row mandatory v-model="expenseApprovalDTO.approved">
              <v-radio color="primary" :value="false" :label="$t('leave.request.denied')" />
              <v-radio color="primary" :value="true" :label="$t('leave.request.approved')" />
            </v-radio-group>
          </v-flex>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn depressed @click.stop="onClickSave">{{ $t("buttons.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "confirm-dialog",
    props: {
      value: Boolean,
      data: Object
    },
    data: () => ({
      dialog: false,
      loading: false,
      expense: null,
      expenseApprovalDTO: {
        approved: false,
        note: null,
        approvedAmount: null
      },
      currencyCode: null,
      requestedAmount: null
    }),
    watch: {
      value: {
        handler(status) {
          this.dialog = status;
        },
        immediate: true
      },
      data: {
        handler(data) {
          if (data) {
            this.expense = data;
            this.currencyCode = data.expense.requested.code;
            this.expenseApprovalDTO.approvedAmount = data.expense.requested.amount;
          }
        },
        deep: true
      }
    },
    methods: {
      onClickCancel() {
        this.expenseApprovalDTO.approved = null;
        this.expenseApprovalDTO.note = null;
        this.expenseApprovalDTO.approvedAmount = null;
        this.expense = null;
        this.$emit("close");
      },
      onClickSave() {
        this.loading = true;
        this.$refs.form.validate().then(valid => {
          if (valid) {
            this.$api.expenseService
              .approve(this.expense.id, this.expenseApprovalDTO)
              .then(data => {
                if (data) {
                  this.$emit("success");
                  this.expenseApprovalDTO.note = null;
                  this.expenseApprovalDTO.note = null;
                  this.expenseApprovalDTO.approvedAmount = null;
                  this.expense = null;
                }
              })
              .catch(e => {
                console.log("e", e);
              })
              .then(() => {
                this.loading = false;
              });
          }
        });
      }
    }
  };
</script>

<style scoped></style>
